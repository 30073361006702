<template lang="html">
  <div id="get-glasses-page">
    <section class="fourth-section">
      <div class="box-step-wr padding-b-plan step-finalized">
        <div class="wr">
          <div class="container">
            <div class="row">
              <div class="col-12 col-xl-5 mx-auto">
                <h2 class="h1s f-w-800 txt-blue">¡Listo!</h2>

                <h3 class="mt-4 f-w-700">Pronto estrenarás lentes.</h3>

                <h5 class="mt-4 f-w-600">Te hemos enviado un correo confirmando tu cita y tu pago.</h5>

                <h5 class="mt-2 f-w-600">También puedes descargarlo <a class="f-w-700 txt-white">aquí</a></h5>
              </div>

              <div class="col-12 mt-5 text-center col-btn-navs">
                <router-link class="btn btn-lg btn-s1 bg-blue px-5" to="/obtener-lentes">Quiero otros lentes</router-link>
              </div>
            </div>
          </div>

          <div class="box-head-s3"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>
