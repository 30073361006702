var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"about-us-page-v2"}},[_c('section',{staticClass:"main-section"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('div',{staticClass:"row box-form"},[_c('div',{staticClass:"col-lg-10 col-info"},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"box-content"},[_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_c('b-form',{staticClass:"form",on:{"submit":_vm.onSubmitAf}},[_c('b-form-group',{staticClass:"custom-f-group-s1"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Nombre","size":"lg","required":""},model:{value:(_vm.formAf.name),callback:function ($$v) {_vm.$set(_vm.formAf, "name", $$v)},expression:"formAf.name"}})],1),_vm._v(" "),_c('b-form-group',{staticClass:"custom-f-group-s1"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Teléfono","minlength":"10","maxlength":"10","size":"lg","required":""},model:{value:(_vm.formAf.phone),callback:function ($$v) {_vm.$set(_vm.formAf, "phone", $$v)},expression:"formAf.phone"}})],1),_vm._v(" "),_c('b-form-group',{staticClass:"custom-f-group-s1"},[_c('b-form-input',{attrs:{"type":"email","placeholder":"Correo electrónico","size":"lg","required":""},model:{value:(_vm.formAf.email),callback:function ($$v) {_vm.$set(_vm.formAf, "email", $$v)},expression:"formAf.email"}})],1),_vm._v(" "),_c('p',{staticClass:"pt-2 text-center"},[_c('b-button',{staticClass:"btn-s2 bg-purple",attrs:{"type":"submit"}},[_vm._v("Afíliate con nosotros")])],1)],1)],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row box-info"},[_c('div',{staticClass:"col-lg-6 col-text"},[_c('h2',{staticClass:"title-s1 txt-purple"},[_vm._v("Por qué"),_c('br'),_vm._v("estás aquí")]),_vm._v(" "),_c('div',{staticClass:"txt"},[_vm._v("\n            Hay mucha gente que necesita\n            lentes, pero no lo sabe. Sí, es muy\n            probable que tú seas una de ellas.\n            Y si no tú, con seguridad alguien\n            cercano a ti. No, no es un dato\n            sacado de la manga, las\n            estadísticas lo confirman: sólo en\n            México, existen 60 millones de\n            personas que necesitan lentes (es\n            decir, ¡más de la mitad!), pero sólo\n            15 millones los usan.\n          ")])]),_vm._v(" "),_c('div',{staticClass:"col-lg-8 col-image"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"public/images/pages/about-us/image-1.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row box-info mt-5 pt-1"},[_c('div',{staticClass:"col-lg-6 col-text"},[_c('h2',{staticClass:"title-s1 txt-purple"},[_vm._v("Necesitas lentes,"),_c('br'),_vm._v("y eso está bien")]),_vm._v(" "),_c('div',{staticClass:"txt"},[_vm._v("\n            Si eres parte de esa mayoría que\n            necesita lentes, pero aún no te\n            atreves a usarlos, tal vez te sirva\n            saber que la cultura del uso de\n            lentes está cada vez más extendida\n            y las comodidades que los nuevos\n            diseños y tecnologías ópticas te\n            ofrecen son tales, que adaptarse a\n            ellos es cada día más fácil.\n          ")])]),_vm._v(" "),_c('div',{staticClass:"col-lg-8 col-image"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"public/images/pages/about-us/image-2.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row box-info mt-4 mt-sm-5 pt-sm-1"},[_c('div',{staticClass:"col-lg-6 col-text"},[_c('h2',{staticClass:"title-s1 txt-purple"},[_vm._v("Tu salud,"),_c('br'),_vm._v("de eso se trata")]),_vm._v(" "),_c('div',{staticClass:"txt"},[_vm._v("\n            Como verás, además de ayudarte a\n            saber si puedes o no necesitarlos,\n            te ofrecemos soluciones muy\n            accesibles para que te hagas de tus\n            primeros lentes. Pero no sólo eso:\n            en el mercado hay calidades muy\n            variadas y, con facilidad, puedes\n            encontrar lentes muy baratos, pero\n            con tecnología y materiales que\n            dejan mucho que desear. Nosotros\n            nos hemos ocupado de sólo\n            ofrecerte productos de calidad\n            comprobada y tecnología de\n            vanguardia, como las lentes\n            procesadas en los\n            laboratorios Augen.\n          ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row box-partner mt-5 pt-1"},[_c('div',{staticClass:"col-lg-6 col-info"},[_c('h2',{staticClass:"title-s1 txt-purple"},[_vm._v("Nuestros"),_c('br'),_vm._v("aliados")]),_vm._v(" "),_c('div',{staticClass:"box-images"},[_c('div',{staticClass:"b-logo"},[_c('img',{staticClass:"logo",attrs:{"src":"public/images/pages/about-us/logo-1.jpg"}})]),_vm._v(" "),_c('div',{staticClass:"b-logo"},[_c('img',{staticClass:"logo",attrs:{"src":"public/images/pages/about-us/logo-1.jpg"}})]),_vm._v(" "),_c('div',{staticClass:"b-logo"},[_c('img',{staticClass:"logo",attrs:{"src":"public/images/pages/about-us/logo-1.jpg"}})]),_vm._v(" "),_c('div',{staticClass:"b-logo"},[_c('img',{staticClass:"logo",attrs:{"src":"public/images/pages/about-us/logo-1.jpg"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-image"},[_c('img',{staticClass:"glasses",attrs:{"src":"public/images/pages/home/h-glasses-3.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"title-s1 txt-purple"},[_vm._v("¿Eres dueño"),_c('br'),_vm._v("de una óptica?")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"subtitle"},[_vm._v("\n              Afiliate con nosotros,"),_c('br'),_vm._v("es rápido, fácil y sin costo.\n            ")])
}]

export { render, staticRenderFns }